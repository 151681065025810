import React from "react";
import AnimatedBackground from "./AnimatedBackground";
import NorwalkUnidesLogo from "../images/norwalk_unides_logo.png";
import "../styles/Hero.css";

const Hero: React.FC = () => {
  return (
    <div className="hero-container">
      <AnimatedBackground />
      <div className="hero-container-title">
        <div className="hero-container-content-title">
          <h1>
            State of
            <br />
            Norwalk 2023
          </h1>
          <img
            className="logo"
            src={NorwalkUnidesLogo}
            alt="Norwalk Unides Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
