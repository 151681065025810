import React from "react";
import "../styles/Introduction.css";

const Introduction: React.FC = () => {
  return (
    <section>
      <hr className="hr-thick"/>
      <h1>Introduction</h1>
      <div className="introduction-section-summary">
        <p>
          Welcome to our interactive data visualization website, where you can explore the budget, transportation, and housing statistics of Norwalk in a visually engaging way. These are key areas of focus for the city, and our goal is to provide insights that will help drive positive change. Discover patterns and relationships in the data, and see how Norwalk can improve in these key areas.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
