// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/sankey
import React from "react";
import { ResponsiveSankey as Component } from "@nivo/sankey";

const data = {
  nodes: [
    {
      id: "John",
      nodeColor: "hsl(112, 70%, 50%)",
    },
    {
      id: "Raoul",
      nodeColor: "hsl(131, 70%, 50%)",
    },
    {
      id: "Jane",
      nodeColor: "hsl(222, 70%, 50%)",
    },
    {
      id: "Marcel",
      nodeColor: "hsl(304, 70%, 50%)",
    },
    {
      id: "Ibrahim",
      nodeColor: "hsl(123, 70%, 50%)",
    },
    {
      id: "Junko",
      nodeColor: "hsl(66, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Marcel",
      target: "Junko",
      value: 79,
    },
    {
      source: "Ibrahim",
      target: "Jane",
      value: 181,
    },
    {
      source: "Ibrahim",
      target: "John",
      value: 158,
    },
    {
      source: "Ibrahim",
      target: "Junko",
      value: 96,
    },
    {
      source: "Raoul",
      target: "Junko",
      value: 165,
    },
    {
      source: "John",
      target: "Marcel",
      value: 134,
    },
    {
      source: "John",
      target: "Raoul",
      value: 84,
    },
    {
      source: "John",
      target: "Jane",
      value: 26,
    },
    {
      source: "Junko",
      target: "Jane",
      value: 77,
    },
  ],
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ResponsiveSankey = ({ input = data } = {}) => (
  <Component
    data={input}
    align="justify"
    colors={{ scheme: "category10" }}
    nodeOpacity={1}
    nodeHoverOthersOpacity={0.35}
    nodeThickness={18}
    nodeSpacing={24}
    nodeBorderWidth={0}
    nodeBorderColor={{
      from: "color",
      modifiers: [["darker", 0.8]],
    }}
    nodeBorderRadius={3}
    linkOpacity={0.5}
    linkHoverOthersOpacity={0.1}
    linkContract={3}
    enableLinkGradient={true}
    labelPosition="outside"
    labelOrientation="vertical"
    labelPadding={16}
    labelTextColor={{
      from: "color",
      modifiers: [["darker", 1]],
    }}
  />
);

export default ResponsiveSankey;
