import React from "react";
import "../styles/Footer.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <p>Copyright &copy; Norwalk Unides 2022. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
