import React from "react";
import "../styles/StatSection.css";

const StatSection = ({
  title,
  description,
  elements,
  height = 80,
}: {
  title: string;
  description: string;
  elements: Array<any>;
  height: number;
}) => {
  return (
    <section className="stat-section-container">
      <hr className="hr-thick" />
      <h2>{title}</h2>
      <div className="stat-section-summary">
        <p>{description}</p>
      </div>
      <hr className="hr-thin" />
      <div style={{ height: `${height}rem`, display: "flex", flexDirection: "column" }}>
        {elements.map((item: any) => {
          return item;
        })}
      </div>
    </section>
  );
};

export default StatSection;
