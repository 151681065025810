// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import React from "react";
import "../styles/main.css";
import Hero from "../components/Hero";
import Introduction from "../components/Introduction";
import StatSection from "../components/StatSection";
import ResponsiveSankey from "../components//ResponsiveSankey";
import ResponsivePieChart from "../components//ResponsivePieChart";
import Footer from "../components/Footer";

const budgetData = [
  {
    label: "Mayor/Council/Boards",
    id: "Mayor/Council/Boards",
    value: 0.5, // 512653,
    color: "hsl(89, 70%, 50%)",
  },
  {
    label: "Administration",
    id: "Administration",
    value: 1.34, // 1349080,
    color: "hsl(181, 70%, 50%)",
  },
  {
    label: "City Attorney",
    id: "City Attorney",
    value: 0.36, // 360000,
    color: "hsl(127, 70%, 50%)",
  },
  {
    label: "Management Services",
    id: "Management Services",
    value: 0.62, // 619781,
    color: "hsl(355, 70%, 50%)",
  },
  {
    label: "Communications/Public Affairs",
    id: "Communications/Public Affairs",
    value: 1.21, // 1212725,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Cable TV",
    id: "Cable TV",
    value: 0.36, // 365208,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Network Contractor",
    id: "Network Contractor",
    value: 0.89, // 891600,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "City Clerk",
    id: "City Clerk",
    value: 1.17, // 1171459,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Human Resources",
    id: "Human Resources",
    value: 4.69, // 4687084,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Finance",
    id: "Finance",
    value: 2.76, // 2763186,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Debt Service",
    id: "Debt Service",
    value: 1.61, // 1616547,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Public Safety",
    id: "Public Safety",
    value: 17.2, // 17269013,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Sheriffs Contract",
    id: "Sheriffs Contract",
    value: 5.87, // 5870660,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Homeless Outreach",
    id: "Homeless Outreach",
    value: 0.85, // 855080,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Public Services",
    id: "Public Services",
    value: 13.7, // 13754720,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Social Services",
    id: "Social Services",
    value: 3.42, // 3427709,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Recreation",
    id: "Recreation",
    value: 5.54, // 5543628,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Community Development",
    id: "Community Development",
    value: 4.09, // 4091630,
    color: "hsl(191, 70%, 50%)",
  },
  {
    label: "Transfers to Capital Reserve Funds",
    id: "Transfers to Capital Reserve Funds",
    value: 8.72, // 8722000,
    color: "hsl(191, 70%, 50%)",
  },
];

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const IndexPage = () => (
  <>
    <header>
      <Hero />
    </header>
    <main>
      <Introduction />
      {[
        {
          title: "Budget",
          height: 75,
          description:
            "The budget section of our data visualization website is a comprehensive resource for all things related to the financial landscape of Norwalk. Explore interactive graphs and charts to gain a deeper understanding of where the money is going and how it's being used to drive positive change in Norwalk.",
          elements: [
            <ResponsivePieChart key={"budgetPie"} input={budgetData} />,
          ],
        },
        {
          title: "Housing",
          height: 75,
          description:
            "Discover the rental affordability landscape of Norwalk with our interactive data visualizations. This section provides insights into rental rates, availability, and affordability in the city. Explore graphs and maps to gain a deeper understanding of the housing situation and its impact on residents.",
          elements: [<ResponsiveSankey key={"housing"} />],
        },
        {
          title: "Bike Infrastructure",
          height: 100,
          description:
            "Explore the state of bicycle infrastructure in Norwalk with our interactive data visualizations. This section provides insights into bike lanes, bike parking, and other aspects of bicycle transportation in the city. Discover patterns and trends in the data and see how Norwalk is working to make cycling a safer and more accessible mode of transportation.",
          elements: [
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1oGYmCn00EKOXfIi88da5DpVEJUnNOd0&ehbc=2E312F"
              height='1000'
            ></iframe>,
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1oGYmCn00EKOXfIi88da5DpVEJUnNOd0&ehbc=2E312F"
              height='1000'
            ></iframe>,
          ],        },
      ].map((item) => {
        return (
          <StatSection
            key={item.title}
            title={item.title}
            elements={item.elements}
            description={item.description}
            height={item.height}
          />
        );
      })}
    </main>
    <Footer />
  </>
);

export default IndexPage;
