import React, { useRef, useEffect } from 'react';

const AnimatedBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    var gradient = ctx.createRadialGradient(
      window.innerWidth / 2,
      window.innerHeight / 2,
      50,
      window.innerWidth / 2,
      window.innerHeight / 2,
      400,
    );
    gradient.addColorStop(0, 'black');
    gradient.addColorStop(1, '#345C72');

    let shapes: Array<{ x: number; y: number; xChange: number; yChange: number; fillStyle: string; radius: number }> = [];

    let shapesInitialized = false;
    let animationFrameId: number;

    function animate() {
      if (!canvas || !ctx) return;
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight

      if (!shapesInitialized) {
        for (let i = 0; i < 100; i++) {
          const radius = (Math.random() * 50 + 10) / 2;
          shapes.push({
            x: (Math.random() * canvas.width) + radius,
            y: (Math.random() * canvas.height) + radius,
            fillStyle: '#345C72',
            xChange: (Math.random() - 0.5),
            yChange: (Math.random() - 0.5),
            radius,
          });
        }
        shapesInitialized = true;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Only animate the shapes every 30th frame
      for (const shape of shapes) {
        ctx.beginPath();
        ctx.arc(shape.x, shape.y, shape.radius, 0, 2 * Math.PI);
        ctx.fillStyle = gradient;
        ctx.strokeStyle = "#000000";
        ctx.fill();
        ctx.stroke();

        if (Math.abs(shape.xChange) < Number.EPSILON || Math.random() > 0.99) {
          shape.xChange = (Math.random() - 0.5);
        }
        if (Math.abs(shape.yChange) < Number.EPSILON || Math.random() > 0.99) {
          shape.yChange = (Math.random() - 0.5);
        }
        shape.x += shape.xChange;
        shape.y += shape.yChange;
        if ((shape.x - shape.radius) <= 0 || (shape.x + shape.radius) > canvas.width) {
          shape.xChange = -shape.xChange;
        }
        if ((shape.y - shape.radius) <= 0 || (shape.y + shape.radius) > canvas.height) {
          shape.yChange = -shape.yChange;
        }
      }

      animationFrameId = window.requestAnimationFrame(animate);
    }

    animate();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    }
  }, []);

  return (
    <canvas
      id='animation-canvas'
      ref={canvasRef}
    />
  );
};

export default AnimatedBackground;
